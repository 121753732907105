import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    StramingDetails02,
    VideoStreamingContainerFour,
} from "gatsby-theme-container";

const StreamigDetailsTemplate = ({ data }) => (
    <Layout>
        <SEO pageName="Movie Details" />
        <HeaderOne data={{ ...data?.header, ...data.navigation }} />
        <StramingDetails02 data={data?.videoStreamingMovie} />
        <VideoStreamingContainerFour
            data={{
                items: data?.allLatestMoviesPost?.nodes,
            }}
        />
        <FooterOne data={{ ...data?.footer, ...data?.contact }} />
    </Layout>
);
export const query = graphql`
    query StreamigMoviesTwoTemplateQuery($slug: String!) {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        videoStreamingMovie(slug: { eq: $slug }) {
            ...StreamingMovieDetails
        }
        allLatestMoviesPost: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
    }
`;

StreamigDetailsTemplate.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        sidebar: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        videoStreamingMovie: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allLatestMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default StreamigDetailsTemplate;
